import Vue from 'vue'
import App from './App.vue'
import { router } from './router/index';
import Ajax from './lib/ajax';
import Util from './lib/util';
import PushNotifications from './lib/notifications';

import vuetify from './lib/vuetify'
import VuetifyToast from 'vuetify-toast-snackbar'  
import VueSweetalert2 from 'vue-sweetalert2';
import VueTheMask from 'vue-the-mask'
import Chat from 'vue-beautiful-chat'
import wysiwyg from "vue-wysiwyg";

import 'sweetalert2/dist/sweetalert2.min.css';
import "vue-wysiwyg/dist/vueWysiwyg.css";

Vue.use(wysiwyg, {}); // config is optional. more below
Vue.use(Chat)
Vue.use(VueTheMask)
Vue.use(VuetifyToast);
Vue.use(VueSweetalert2);
Vue.use(require('vue-shortkey'), { prevent: ['.excl-shortkey input', 'textarea'] });

import store from './store/index';
//import axios from 'axios'
//import VueAxios from 'vue-axios'

Vue.config.productionTip = false
 
//Vue.use(VueAxios, axios) 

import { appRouters, otherRouters } from './router/router';
const moment = require('moment')
require('moment/locale/es')
Vue.use(require('vue-moment'), { moment })

Ajax.get('/api/getUserConfigurations').then(data=>{
    Util.abp = Util.extend(true, Util.abp, data.data.result);
}).finally(function () {    

    new Vue({
        vuetify,
        router: router,
        store: store,
        render: h => h(App),
        data: {
            currentPageName: ''
        },
        async mounted() {
            this.currentPageName = this.$route.name as string;

            if (!!Util.abp.session.userId) {
                await this.$store.dispatch({ type: 'session/init' });

                PushNotifications.init(this);

                let tabList = await this.$store.dispatch({ type: 'tab/getTabs' });
                Util.abp.tabList = Util.buildMenuRoutes(this, tabList);

                if (Util.abp.tabList.length == 1)
                    this.$store.commit('app/setShrink', true);
                 
                let tagsList: Array<any> = [];
                if (Util.abp.tabList != undefined) {
                    Util.abp.tabList.map((item) => {
                        if (item.children != null) {
                            if (item.children.length == 0) {
                                tagsList.push(item);
                            } else if (item.children.length == 1) {
                                tagsList.push(item.children[0]);
                            } else {
                                tagsList.push(...item.children);
                            }
                        } else
                            tagsList.push(item);
                    });
                }
                this.$store.state.app.routers.push(...Util.abp.tabList);
                this.$store.commit('app/setTagsList', tagsList);
                this.$store.commit('app/initCachepage');
                this.$store.commit('app/updateMenulist');
            }
        },
        created() {
            let tagsList: Array<any> = [];
            appRouters.map((item) => {
                if (item.children != undefined) {
                    if (item.children.length <= 1) {
                        tagsList.push(item.children[0]);
                    } else {
                        tagsList.push(...item.children);
                    }
                }
            });

            this.$store.commit('app/setTagsList', tagsList);
        }
    }).$mount('#app')
})

