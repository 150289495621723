import Entity from '../entity'

export default class Customer extends Entity<number>{
    code: number;
    person_type_id: number;
    document_type_id: number;
    firstname: string;
    lastname: string;
    company: string;
    document: string;
    phone: string;
    email: string;
    active: boolean;
    existing: boolean;
}


