import Vue from 'vue';
import Vuetify, { VSnackbar, VBtn, VIcon } from 'vuetify/lib';
import VuetifyToast from 'vuetify-toast-snackbar';
import 'vuetify/dist/vuetify.min.css';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify, {
    components: {
        VSnackbar,
        VBtn,
        VIcon   
    }
})

const opts = { 
    theme: {
        //dark:true,
        themes: {
            light: {
                primary: '#43A047', 
                secondary: '#424242',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107', 
            }
        },
        options: {
            customProperties: true,
        },
    },
};

const vueObj = new Vuetify(opts);
export default vueObj

Vue.use(VuetifyToast, { y: 'bottom', x: 'center', icon: 'info', $vuetify: vueObj.framework });
