import Entity from '../entity'

export default class ApplicationDocument extends Entity<number>{
    application_id: number;
    document_type_id: number;
    name: string;
    description: string;
    filename: string;
    filetype: string;
    verified: boolean;

    fileData: string;
}


