import Entity from '../entity'

export default class ApplicationGood extends Entity<number>{
    application_id: number;
    number: number;
    manufacturer: string;
    quantity: number;
    good: string;
    prepaid: number;
    months: number;
    tea: number;
    days_stock: number;
    profit: number;
    total: number; 
}


