import Vue from 'vue';
import VueRouter from 'vue-router';
import {routers} from './router';
//import iView from 'iview';
import Util from '../lib/util';
import Cookies from 'js-cookie'
import store from '../store/index';
import { appRouters, otherRouters } from './router'

Vue.use(VueRouter);

const RouterConfig = {
    // mode: 'history',
    routes: routers
};

export const router = new VueRouter(RouterConfig);

router.beforeEach((to, from, next) => {
    //iView.LoadingBar.start();
    //(store.state as any).app.loading = true; 
    Util.title(to.meta.title);
    if (Cookies.get('locking') === '1' && to.name !== 'locking') {
        next({
            replace: true,
            name: 'locking'
        });
    } else if (Cookies.get('locking') === '0' && to.name === 'locking') {
        next(false);
    } else if (to.name != undefined  && to.name.indexOf('public') >= 0) {
        next();
    } else {
        if (!Util.abp.session.userId&& to.name !== 'login') {
            next({
                name: 'login'
            });
        } else if (!!Util.abp.session.userId && to.name === 'login') {
            Util.title(to.meta.title);
            next({
                name: Util.abp.session.tab
            }); 
        } else {

            //const curRouterObj = Util.getRouterObjByName([otherRouters, ...appRouters, ...Util.abp.menuListDb], to.name);
	        const curRouterObj = Util.getRouterObjByName([otherRouters, ...appRouters], to.name);
            if (curRouterObj && curRouterObj.permission) {
                if (window.abp.auth.hasPermission(curRouterObj.permission + '.view')) {
                    Util.toDefaultPage([otherRouters, ...appRouters], to.name, router, next);
                } else {
                    next({
                        replace: true,
                        name: 'error-403'
                    });
                }
            } else {
                Util.toDefaultPage([...routers], to.name, router, next);
            }
        }
    }
});
router.afterEach((to) => {
    Util.openNewPage(router.app, to.name, to.params, to.query);
    //iView.LoadingBar.finish();
    //(store.state as any).app.loading = false;
    window.scrollTo(0, 0);
});
