import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import ApplicationProposal from '../../entities/financial/applicationproposal'
import Util from '../../../lib/util'

interface ApplicationProposalState extends ListState<ApplicationProposal>{
    editModel:ApplicationProposal;    
    application:number;    
}
class ApplicationProposalModule extends ListModule<ApplicationProposalState,any,ApplicationProposal>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<ApplicationProposal>(),
        loading:false,
        editModel: new ApplicationProposal(),
        activeList: new Array<ApplicationProposal>(),
        application: 0,
        path: 'applications'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<ApplicationProposalState, any>, payload: any) {
            context.state.list = [];
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: ApplicationProposalState, model: ApplicationProposal) {
            state.editModel = model;
        },
        init(state: ApplicationProposalState, model: any) {
            state.editModel = Object.assign({});
            state.list = [];
            if (model != undefined) {
                state.application = model.id;
                state.list = model.proposals;
            }
        },
        add(state: ApplicationProposalState, item: any) {
            let detail = new ApplicationProposal();
            detail.application_id = state.application;
            detail.operation_id = Util.abp.list.get('T_OPERATION','N').id;
            detail.model = '';
            detail.vehicle_type = '';
            detail.quantity = 1;
            detail.months = 24;
            detail.day_fee = 0;
            detail.month_fee = 0;
            detail.price = 0;
            detail.equipment = 0;
            detail.total = 0;
            detail.total_amount = 0;
            state.list.push(detail);
        },
        delete(state: ApplicationProposalState, index: number) {
            state.list.splice(index, 1);
        },
    });
}
const applicationdetailModule=new ApplicationProposalModule();
export default applicationdetailModule;