import Entity from '../entity'

export default class FinancialRating extends Entity<number>{
    customer_id: number;
    nota: number;
    nro_bancos: number;
    sema_actual: number;
    deuda_total: number;
    deuda_sbs: number;
    deuda_impaga: number;
    fecha_proceso: string;
    calificativo: string;
}


