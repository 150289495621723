












































































































    import { Component, Vue,Inject, Prop,Watch } from 'vue-property-decorator';
    import AbpBase from '../../lib/abpbase'
    import Util from '@/lib/util';

@Component
export default class Notice extends AbpBase{
    tabModel: any = null;
    fav: boolean = true;
    menu: boolean = false;
    message: boolean = false;
    hints: boolean = true;

    get noticeList():Array<any>{
        return this.$store.state.notification.list;
    }
    get loading(): boolean {
        return this.$store.state.notification.loading;
    }
    get unReadCount() {
        return this.noticeList.filter(n => n.readed == false).length;
    }
    get noticeCount() {
        return this.noticeList.filter(n => n.type === 0).length
        //return this.noticeList.filter(n=>n.readed==false&&n.type===0).length
    }
    get messageCount() {
        return this.noticeList.filter(n => n.readed==false&&n.type===1).length
    }
    readNoty(notice, index) {        
       if (!notice.readed) {
            this.$store.commit('notification/readNotify', index);
            this.$store.dispatch({
                type: 'notification/update',
                data: { id: this.noticeList[index].id }
            });
        }
        
        if (notice.data != null && notice.data.entity != null) {
            if (notice.data.entity == 'category-budget')
                Util.toRoute(this, notice.data.entity, {
                    request: true,
                    year: notice.data.year,
                    month: notice.data.month,
                    line: notice.data.line_id,
                    category: notice.data.category_id,
                    purchase_planning_id: notice.data.id,
                    description: notice.description
                });
            else
                 Util.toRoute(this, notice.data.entity, { id: notice.data.id });
        } 
    }
    getNotices() {
        this.$store.dispatch({
            type: 'notification/getAll'
        });
    }
    genChildTarget(item) {
        return { name: item.entity, query: { id: item.entityId, filter: item.filterId,edit:true} };
    } 
    mounted() {
        this.getNotices();
    }
}
