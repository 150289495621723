import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
import app from './modules/app'
import session from './modules/session'
import user from './modules/admin/user'
import role from './modules/admin/role'
import tab from './modules/admin/tab'
import company from './modules/admin/company'
import dashboard from './modules/admin/dashboard'

import analyst from './modules/catalogs/analyst'
import customer from './modules/catalogs/customer'
import documenttype from './modules/catalogs/documenttype'
import currency from './modules/catalogs/currency'
import manufacturer from './modules/catalogs/manufacturer'
import manufacturermodel from './modules/catalogs/manufacturermodel'
import alert from './modules/catalogs/alert'

import application from './modules/financial/application'
import applicationdocument from './modules/financial/applicationdocument'
import applicationproposal from './modules/financial/applicationproposal'
import applicationcomment from './modules/financial/applicationcomment'
import concept from './modules/financial/concept'
import financialstatement from './modules/financial/financialstatement'
import financialrating from './modules/financial/financialrating'
import riskreport from './modules/financial/riskreport'

 
import generaltype from './modules/preferences/generaltype'
import generaltypevalue from './modules/preferences/generaltypevalue'
import configuration from './modules/preferences/configuration'
import notification from './modules/preferences/notification'
import conversation from './modules/preferences/conversation'



const store = new Vuex.Store({
    state: {
        //
        //loading: boolean
    },
    mutations: {
        //
    },
    actions: {

    },
    modules: {
        app,
        session,
        user,
        role, 
        tab,
        company,
        dashboard,

        analyst,
        alert,
        customer,
        documenttype,
        manufacturer,
        manufacturermodel,
        currency,

        application,
        applicationdocument,
        applicationproposal,
        applicationcomment,
        concept,
        financialstatement,
        financialrating,
        riskreport,

        configuration,
        generaltype,
        generaltypevalue,
        notification,
        conversation,

    }
});

export default store;