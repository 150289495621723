import { Store, Module, ActionContext } from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import RiskReport from '../../entities/financial/riskreport'
import Util from '../../../lib/util'
import Ajax from '../../../lib/ajax'
import RiskReportProposal from '@/store/entities/financial/riskreportproposal';

interface RiskReportState extends ListState<RiskReport> {
    editModel: RiskReport;
    reportList: Array<any>;
    filename: '';
}
class RiskReportModule extends ListModule<RiskReportState, any, RiskReport>{
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<RiskReport>(),
        loading: false,
        editModel: new RiskReport(),
        activeList: new Array<RiskReport>(),
        reportList: new Array<any>(),
        filename: '',
        path: 'risk-reports'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getRisk(context: ActionContext<RiskReportState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/risk-reports/get', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return reponse.data.result;
        },
        async deleteImage(context: ActionContext<RiskReportState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.post('/api/risk-reports/delete-image', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return reponse.data.result;
        },
        async deleteFieldImage(context: ActionContext<RiskReportState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.post('/api/risk-reports/delete-field-image', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return reponse.data.result;
        },
        async download(context: ActionContext<RiskReportState, any>, payload: any) {
            context.state.loading = true;
            let data = { params: payload.data };
            data['responseType'] = 'blob';
            let response = await Ajax.get('/api/risk-reports/download', data).then(response => {
                const link = document.createElement('a');
                const url = window.URL.createObjectURL(new Blob([response.data]));
                link.href = url; 
                link.setAttribute('download', payload.data.filename); //or any other extension
                document.body.appendChild(link);
                link.click();
            }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
        async updateTabs(context: ActionContext<RiskReportState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.post('/api/risk-reports/update-tabs', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.editModel.tabs = reponse.data.result.tabs;
            return reponse.data.result;
        },
    }); 
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: RiskReportState, model: RiskReport) {
            var today = new Date();
            state.editModel = Object.assign({});
            state.editModel.proposals = [];
            state.editModel.indicators_nro = 1;
            state.editModel.statement_nro = 1;

            let prop = new RiskReportProposal();
            prop.number = 1;
            prop.proposal_type = null;
            state.editModel.proposals.push(prop);
        },
        edit(state: RiskReportState, model: any) {
            state.editModel = model;
            if (state.editModel.proposals == undefined) state.editModel.proposals = [];

            let prop = new RiskReportProposal();
            prop.number = state.editModel.proposals.length + 1;
            prop.proposal_type = null;
            state.editModel.proposals.push(prop);
        },
        addProposal(state: RiskReportState, model: any) {
            let prop = new RiskReportProposal();
            let count = state.editModel.proposals.filter(item => { return item.proposal_type == null || item.proposal_type == '' }).length;
            if (count == 0) {
                prop.number = state.editModel.proposals.length + 1;
                prop.proposal_type = null;
                state.editModel.proposals.push(prop);
            }
        },
        deleteProposal(state: RiskReportState, index: number) {
            state.editModel.proposals.splice(index, 1);
        },
        addField(state: RiskReportState, model: any) {
            state.editModel.fields.push(model);
        },
        removeField(state: RiskReportState, model: any) {
            let index = -1;
            state.editModel.fields.filter((i,k) => {
                if (i.field_id == model.field_id && i.number == model.number) {
                    index = k;
                }
            });
            if (index >= 0) {
                state.editModel.fields.splice(index, 1);
            }
        },
    });
}
const riskreportModule = new RiskReportModule();
export default riskreportModule;