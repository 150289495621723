import Entity from '../entity'

export default class RiskReportProposal extends Entity<number>{
    risk_report_id: number;
    number: number;
    proposal_type: string;
    model: string;
    quantity: number;
    months: number;
    tea: number;
    initial: number;
    total: number;
    total_investment: number;
    total_monthly: number;
    factor: number;
    van: number;
    tir: number;
    days_stock: number;
    gross_margin: number;
    net_margin: number;
    profitability: number;
    vr: number;
    cuotes: number;
    warranty: string;
}


