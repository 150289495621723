import Entity from '../entity'
import ManufacturerModel from './manufacturermodel';

export default class Manufacturer extends Entity<number>{
    name: string;
    active: boolean;
    models: Array<ManufacturerModel>;
}


