import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Alert from '../../entities/catalogs/alert'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface AlertState extends ListState<Alert>{
    editModel:Alert;
    
}
class AlertModule extends ListModule<AlertState,any,Alert>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Alert>(),
        loading:false,
        editModel: new Alert(),
        activeList: new Array<Alert>(),
        path: 'alerts'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions        
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const alertModule=new AlertModule();
export default alertModule;
