import Entity from '../entity'

export default class Analyst extends Entity<number>{
    code: number;
    firstname: string;
    lastname: string;
    document: string;
    phone: string;
    email: string;
    active: boolean;
}


