


















































import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import shrinkableMenu from '../components/shrinkable-menu/shrinkable-menu.vue';
import tagsPageOpened from '../components/tags-page-opened.vue';
import util from '../lib/util';
import AppConst from '../lib/appconst'
import Appfooter from '../components/Footer.vue'
import Toolbar from '../components/Toolbar.vue'
import AppFab from '../components/AppFab.vue'
import confirm from '../components/confirm.vue';
//import { appRouters, otherRouters } from './router/router';
import AbpBase from '../lib/abpbase'

@Component({
    components: { Toolbar, AppFab, shrinkableMenu, tagsPageOpened, Appfooter, confirm}
})

export default class Main extends AbpBase {
    get shrink() {
        return this.$store.state.app.shrink;
    }
    get userName(){
      return this.$store.state.session.user?this.$store.state.session.user.name:''
    }
    messageCount: string = '0';
    get computeLogo () {
      return '/static/logo.png';
    }
    get companyName() {
        if (this.$store.state.session.company == undefined) return AppConst.setting.appName;
        return this.$store.state.session.company.name;
    }
    get openedSubmenuArr(){
      return this.$store.state.app.openedSubmenuArr
    }
    get menuList () {
      return this.$store.state.app.menuList;
    }
    get pageTagsList () {
      return this.$store.state.app.pageOpenedList as Array<any>;
    }
    get lang(){
      return this.$store.state.app.lang;
    }
    get avatorPath () {
      return localStorage.avatorImgPath;
    }
    get cachePage () {
      return this.$store.state.app.cachePage;
    }
    get menuTheme () {
      return this.$store.state.app.menuTheme;
    }
    get mesCount () {
      return this.$store.state.app.messageCount;
    }
    init () {
      let pathArr = util.setCurrentPath(this, this.$route.name as string);
      
      this.$store.commit('app/updateMenulist');
      
      if (pathArr.length >= 2) {
        this.$store.commit('app/addOpenSubmenu', pathArr[1].name);
      }
      let messageCount = 3;
      this.messageCount = messageCount.toString();
      this.checkTag(this.$route.name);
    }
    toggleClick() {
        //this.shrink = !this.shrink;
    }
    checkTag (name?:string) {
      let openpageHasTag = this.pageTagsList.some((item:any) => {
        if (item.name === name) {
          return true;
        }else{
          return false
        }
      });
      if (!openpageHasTag) { 
        util.openNewPage(this, name as string, this.$route.params || {}, this.$route.query || {});
      }
    }
    handleSubmenuChange (val:string) {
        //console.log("main - " + val);
    }
    beforePush (name:string) {
      if (name === 'accesstest_index') {
        return false;
      } else {
        return true;
      } 
    }
    @Watch('$route')
    routeChange(to:any){
      this.$store.commit('app/setCurrentPageName', to.name);
      let pathArr = util.setCurrentPath(this, to.name);
      if (pathArr.length > 2) {
        this.$store.commit('app/addOpenSubmenu', pathArr[1].name);
      }
      this.checkTag(to.name);
      localStorage.currentPageName = to.name;
    }
    @Watch('lang')
    langChange(){
      util.setCurrentPath(this, this.$route.name as string);
    }
    mounted() {
        this.$root.$confirm = (this.$refs as any).confirm.open;
        this.init();
    }
    created() {
        this.$store.commit('app/setOpenedList');
    }
}
