import { Store, Module, ActionContext } from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Customer from '../../entities/catalogs/customer'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface CustomerState extends ListState<Customer> {
    editModel: Customer;
    activeList: Array<Customer>;
    reportList: Array<any>;
    filename: string;
}
class CustomerModule extends ListModule<CustomerState, any, Customer>{
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<Customer>(),
        loading: false,
        editModel: new Customer(),
        activeList: new Array<Customer>(),
        reportList: new Array<any>(),
        filename: '',
        path: 'customers'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async get(context: ActionContext<CustomerState, any>, payload: any) {
            if (payload.id == undefined) return {};
            let existing = context.state.list.filter((item: any) => {
                return item.id == payload.id;
            });

            if (existing.length > 0) return existing[0];

            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path + '/get/' + payload.id).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });

            let item = reponse.data.result as Customer;
            context.state.list.push(item);
            context.state.loading = false;
            return item;
        },
        async getLastCode(context: ActionContext<CustomerState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/customers/last-code').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });

            return result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: CustomerState, model: Customer) {
            state.editModel = model;
        },
        new(state: CustomerState, model: Customer) {
            state.editModel = Object.assign({});
            state.editModel.code = 0;
        },
        setReportList(state: CustomerState, list: Array<any>) {
            state.reportList = list;
        },
    });
}
const customerModule = new CustomerModule();
export default customerModule;