import Entity from '../entity'

export default class Concept extends Entity<number>{
    type_id: number;
    group_id: number;
    number: number;
    name: string;
    active: boolean;
}


