import Entity from '../entity'

export default class Currency extends Entity<number>{
    name: string;
    iso_code: string;
    iso_code_num: string;
    sign: string;
    conversion_rate: number;
    decimals: boolean;
    active: boolean;
}


