import { Store, Module, ActionContext } from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Application from '../../entities/financial/application'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PageResult from '@/store/entities/page-result';
import ApplicationProposal from '@/store/entities/financial/applicationproposal';
import ApplicationGood from '@/store/entities/financial/applicationgood';
import ApplicationHistory from '@/store/entities/financial/history';

interface ApplicationState extends ListState<Application> {
    editModel: Application;
    hisLoading: boolean;
    history: Array<ApplicationHistory>;
    goods: Array<ApplicationGood>;
    reportList: Array<any>;
    filename: '';
}
class ApplicationModule extends ListModule<ApplicationState, any, Application>{
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<Application>(),
        loading: false,
        hisLoading: false,
        editModel: new Application(),
        activeList: new Array<Application>(),
        history: new Array<ApplicationHistory>(),
        goods: new Array<ApplicationGood>(),
        reportList: new Array<any>(),
        filename: '',
        path: 'applications'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<ApplicationState, any>, payload: any) {
            if (context.state.loading) return context.state.list;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (typeof reponse.data.result == 'object' && !Array.isArray(reponse.data.result)) {
                let page = reponse.data.result as PageResult<Application>;
                context.state.totalCount = page.total;
                context.state.list = page.data;
            } else {
                context.state.totalCount = reponse.data.result.length;
                context.state.list = reponse.data.result;
            }

            return context.state.list;
        },
        async get(context: ActionContext<ApplicationState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path + '/get/' + payload.id);
            context.state.editModel = reponse.data.result as Application;
            context.state.loading = false;
            return context.state.editModel;
        },
        async update(context: ActionContext<ApplicationState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.editModel = response.data.result;
            return response.data.result;
        },
        async getLastNumber(context: ActionContext<ApplicationState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/applications/last-number').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });
            context.state.editModel.code = result;

            return result;
        },
        async getHistory(context: ActionContext<ApplicationState, any>, payload: any) {
            context.state.hisLoading = true;
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/applications/' + id + '/history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
        async getSentinel(context: ActionContext<ApplicationState, any>, payload: any) {
            context.state.hisLoading = true;
            let reponse = await Ajax.get('/api/applications/sentinel', { params: payload.data }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
        async verify(context: ActionContext<ApplicationState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/applications/verify', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async requestBehavior(context: ActionContext<ApplicationState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/applications/request-behavior', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async agreed(context: ActionContext<ApplicationState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/applications/agreed', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async observe(context: ActionContext<ApplicationState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/applications/observe', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async requestReview(context: ActionContext<ApplicationState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/applications/request-review', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async review(context: ActionContext<ApplicationState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/applications/review', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async approve(context: ActionContext<ApplicationState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/applications/approve', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async close(context: ActionContext<ApplicationState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/applications/close', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async reopen(context: ActionContext<ApplicationState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/applications/reopen', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async getAttentions(context: ActionContext<ApplicationState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/applications/attentions', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result;
            context.state.loading = false;
        },
    }); 
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: ApplicationState, model: Application) {
            var today = new Date();

            state.editModel = Object.assign({});
            state.editModel.issue_date = Util.abp.clock.today();
            state.editModel.code = 0;
            state.editModel.user_id = Util.abp.session.userId;
            state.editModel.vertical_id = Util.abp.setting.get('DEFAULT_VERTICAL');
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_CURRENCY');
            state.editModel.currency_sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            state.editModel.customer_type_id = Util.abp.list.get('T_CUSTOMER', 'N').id;
            state.editModel.exchange_rate = 1;
            state.editModel.autonomy_process = 'C';
            
            state.editModel.proposals = new Array<ApplicationProposal>();
            state.goods = [];

            let prop = new ApplicationGood();
            prop.number = 1;
            prop.good = null;
            state.goods.push(prop);
        },
        edit(state: ApplicationState, model: any) {
            state.editModel = model;
            state.editModel.currency_sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            state.editModel.customer_name = model.customer.description;
            state.editModel.representative_name = (model.representative != null ? model.representative.description : '');
            state.editModel.guarantee_name = (model.guarantee != null ? model.guarantee.description : '');
            state.editModel.existing = model.customer.existing;
            state.goods = state.editModel.goods;

            if (state.goods == undefined || state.goods == null) state.goods = [];

            if (state.goods.length == 0 || (state.goods.length > 0 && state.goods[state.goods.length - 1].good != null)) {
                let prop = new ApplicationGood();
                prop.number = state.goods.length + 1;
                prop.good = null;
                state.goods.push(prop);
            }
        },
        view(state: ApplicationState, model: any) {
            state.editModel = model;
            state.editModel.currency_sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            state.editModel.customer_name = model.customer.description;
            state.editModel.representative_name = (model.representative != null ? model.representative.description : '');
            state.editModel.guarantee_name = (model.guarantee != null ? model.guarantee.description : '');
            state.editModel.currency_sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            state.editModel.existing = model.customer.existing;
        },
        setVotes(state: ApplicationState, votes: Array<any>) {
            state.editModel.votes = votes;
        },
        addGood(state: ApplicationState, model: any) {
            let prop = new ApplicationGood();
            let count = state.goods.filter(item => { return item.good == null || item.good == '' }).length;

            if (count == 0) {
                prop.number = state.goods.length + 1;
                prop.good = null;
                state.goods.push(prop);
            }
        },
        deleteGood(state: ApplicationState, index: number) {
            state.goods.splice(index, 1);
        },
    });
}
const applicationModule = new ApplicationModule();
export default applicationModule;