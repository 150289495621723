import Entity from '../entity'
import RiskReportProposal from './riskreportproposal';

export default class RiskReport extends Entity<number>{
    vertical_id: number;
    application_id: number;
    customer_id: number;
    constitution_date: string;
    customer_type: string;
    sunat_dependency: string;
    sunat_economic_activity: string;
    financing_type: string;
    financial_classification: string;
    exercise: string;
    business_line: string;
    business_line_file: string;
    shareholders: string;
    shareholders_file: string;
    financing_rating: string;
    financing_rating_file: string;
    delays_document: string;
    delays_document_file: string;
    credit_experience: string;
    credit_experience_file: string;
    current_fleet: string;
    current_fleet_file: string;
    current_proposal: string;
    current_proposal_file: string;
    statement: string;
    statement2: string;
    statement3: string;
    statement_file: string;
    statement2_file: string;
    statement3_file: string;
    statement_nro: number;
    indicators: string;
    indicators2: string;
    indicators3: string;
    indicators_file: string;
    indicators2_file: string;
    indicators3_file: string;
    indicators_nro: number;
    obligations: string;
    obligations_file: string;
    conditions: string;
    conditions_file: string;
    annexes: string;
    annexes_file: string;
    logo: string;
    show_proposals: boolean;

    customer: any;
    proposals: Array<RiskReportProposal>;
    fields: Array<any>;
    tabs: Array<any>;
}


