import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import ApplicationComment from '../../entities/financial/applicationcomment'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Application from '@/store/entities/financial/application';

interface ApplicationCommentState extends ListState<ApplicationComment>{
    editModel:ApplicationComment;    
    application: number;
    itemLoading: boolean;
}
class ApplicationCommentModule extends ListModule<ApplicationCommentState,any,ApplicationComment>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<ApplicationComment>(),
        loading: false,
        itemLoading: false,
        editModel: new ApplicationComment(),
        application: 0,
        path: 'application-comments'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        init(state: ApplicationCommentState, model: Application) {
            state.editModel = Object.assign({});
            state.application = 0;
            if (model != undefined) {
                state.application = model.id;
            }
        },
        new(state: ApplicationCommentState, model: ApplicationComment) {
            state.editModel = Object.assign({});
            state.editModel.filename = null;
            state.editModel.fileData = null;
        },
    });
}
const applicationcommentModule=new ApplicationCommentModule();
export default applicationcommentModule;