import { Store, Module, ActionContext, MutationTree } from 'vuex'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface DashboardState {
    figures: any;
    widgets: any;
    applicationBar: Array<any>;
    applicationPie: Array<any>;
    loading: boolean;
    
} 
class DashboardModule implements Module<DashboardState, any>{
    namespaced = true;
    state={
        figures: [],
        widgets: [],
        applicationBar: [],
        applicationPie: [10,10],
        loading:false,
    }
    actions = {
        async getDashTickets(context: ActionContext<DashboardState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/dash-applications', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.applicationBar = response.data.result.bar;
            context.state.applicationPie = response.data.result.pie;
        },
        async getWidgetValues(context: ActionContext<DashboardState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/dash-widgets', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.widgets = response.data.result;
            return context.state.widgets;
        },
    };
    mutations = {
    };
}
const dashboardModule = new DashboardModule();
export default dashboardModule;