import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import FinancialRating from '../../entities/financial/financialrating'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
 
interface FinancialRatingState extends ListState<FinancialRating>{
    editModel: FinancialRating;
    reportList: Array<any>;
    details: Array<any>;
    periods: Array<any>;
    activeList: Array<FinancialRating>;
}
class FinancialRatingModule extends ListModule<FinancialRatingState,any,FinancialRating>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<FinancialRating>(),
        loading: false,
        editModel: new FinancialRating(),
        activeList: new Array<FinancialRating>(),
        reportList: new Array<any>(),
        details: new Array<any>(),
        periods: new Array<any>(),
        path: 'financial-ratings'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getRating(context: ActionContext<FinancialRatingState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/financial-ratings/' + payload.data.customer).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.editModel = reponse.data.result;
            context.state.details = (reponse.data.result != null ? reponse.data.result.details : []);
            context.state.periods = (reponse.data.result != null ? reponse.data.result.periods : []);            
        },
        async processApi(context: ActionContext<FinancialRatingState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.post('/api/financial-ratings-api', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const financialratingModule=new FinancialRatingModule();
export default financialratingModule;
