import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import FinancialStatement from '../../entities/financial/financialstatement'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
 
interface FinancialStatementState extends ListState<FinancialStatement>{
    editModel: FinancialStatement;
    reportList: Array<any>;
    activeList: Array<FinancialStatement>;
}
class FinancialStatementModule extends ListModule<FinancialStatementState,any,FinancialStatement>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<FinancialStatement>(),
        loading: false,
        editModel: new FinancialStatement(),
        activeList: new Array<FinancialStatement>(),
        reportList: new Array<any>(),
        path: 'financial-statements'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<FinancialStatementState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.totalCount = reponse.data.result.length;
            context.state.list = reponse.data.result;
        },
        async updateBatch(context: ActionContext<FinancialStatementState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.post('/api/financial-statement-batch', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
        async updateConcept(context: ActionContext<FinancialStatementState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.put('/api/financial-statement-concept', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
        async export(context: ActionContext<FinancialStatementState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/financial-statements/export', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return reponse.data.result.filename;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: FinancialStatementState, model: FinancialStatement) {
            state.editModel = model;
            
        }, 
        new(state: FinancialStatementState, model: FinancialStatement) {
            state.editModel = Object.assign({});
        },
    });
}
const financialstatementModule=new FinancialStatementModule();
export default financialstatementModule;
