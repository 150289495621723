import Entity from '../entity'

export default class Conversation extends Entity<number>{
    entity: string;
    entity_id: number;
    filter_id: number;
    user_from_id: number;
    user_to_id: number;
    user_from_typing: boolean;
    user_to_typing: boolean;

    messages: Array<any>;
}


