














































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import FormBase from './form-base.vue';
import Util from '../../lib/util'
import AppConst from '../../lib/appconst'
import VWidget from '../VWidget.vue';
import AbpBase from '../../lib/abpbase';    

@Component({
    components: { VuePerfectScrollbar, FormBase, VWidget },
    data() { 
        return {
            model: { type: Object },
            scrollSettings: {
                maxScrollbarLength: 160
            }
        }
    },
    watch: {
        value: function (val, oldVal) {
            let obj = (this as any);
            obj.formBase = val;
            obj.dialog = val;
            if (val) {
                if (obj.setting.showForm) {
                    obj.model = Util.extend(true, {}, this.$store.state[obj.setting.entity].editModel);
                    if (obj.setting.hasAddress) obj.model = Util.setAddressProps(obj.items, obj.model);
                    this.$emit('on-show', obj.model);
                }
            } else if (oldVal && !val) {
                obj.cancel();
            }            
        },
        dialog: function (val, oldVal) {
            let obj = (this as any);
            if (oldVal && !val) {
                obj.cancel();
            }
        },
    },
    computed: {
        setting: {
            get() {
                let obj = (this as any);
                let setts = Util.extend(true, {}, obj.mainSetting);
                if (setts.icon == undefined)
                    setts.icon = 'mdi-pencil-box-outline';
                if (setts.width == undefined)
                    setts.width = '700px';                    
                if (setts.persistent == undefined)
                    setts.persistent = true;
                if (setts.showForm == undefined) 
                    setts.showForm = true;
                if (setts.cancelReset == undefined)
                    setts.cancelReset = true;
                if (setts.formSize == undefined)
                    setts.formSize = '12';
                if (setts.fullscreen == undefined)
                    setts.fullscreen = false;
                if (setts.simpleToolbar == undefined)
                    setts.simpleToolbar = false;                    
                if (setts.showFootActions == undefined)
                    setts.showFootActions = true;
                if (setts.showFloatFootActions == undefined)
                    setts.showFloatFootActions = false;
                if (setts.showDialog == undefined)
                    setts.showDialog = true;
                if (setts.showCancelButton == undefined)
                    setts.showCancelButton = true;
                if (setts.showSaveButton == undefined)
                    setts.showSaveButton = true;
                if (setts.showPrintButton == undefined)
                    setts.showPrintButton = false;                    
                if (setts.hasAddress == undefined)
                    setts.hasAddress = false;
                if (setts.toolbarColor == undefined)
                    setts.toolbarColor = (setts.fullscreen ? 'secondary' : '#f8f8f9');
                if (setts.resetForm == undefined)
                    setts.resetForm = true;       
                if (setts.disabledSave == undefined)
                    setts.disabledSave = false;
                if (setts.customSave == undefined)
                    setts.customSave = false;    
                if (setts.closeOnSave == undefined)
                    setts.closeOnSave = true;
                if (setts.closeOnCancel == undefined)
                    setts.closeOnCancel = true;
                if (setts.disabled == undefined)
                    setts.disabled = false;
                if (setts.saveText == undefined)
                    setts.saveText = (setts.showDialog ? 'Guardar' : 'Agregar');
                if (setts.saveColor == undefined)
                    setts.saveColor = 'primary';
                if (setts.saveIcon == undefined)
                    setts.saveIcon = 'save';              
                if (setts.saveColor == undefined)
                    setts.saveColor = 'primary';
                if (setts.loadingProp == undefined)
                    setts.loadingProp = 'loading';
                
                return setts
            },
            set(value) {
            }
        }
    }
})

export default class ModalView extends AbpBase {
    @Prop({
        type: Object, default: () => {
            return {
                entity: '', title: '', width: '700px', showForm: true, hasAddress: false,
                formSize: '12', fullscreen: false, simpleToolbar: false, showFootActions: true,
                showDialog: true, resetForm: true, disabledSave: false
            }
        }
    }) mainSetting: any;
    @Prop({ type: Boolean, default: false }) value: boolean;
    @Prop({ type: Array, default: () => { return [{ key: 'main', title: '' }] }}) tabs: Array<any>;
    @Prop({ type: Array }) items: Array<any>;
    dialog: boolean = false;
    formBase: boolean = false;
    snackbar: boolean = false;
    form_dirty: boolean = true;

    isNew() {
        let obj = (this as any);
        return this.$store.state[obj.setting.entity].editModel.id == undefined || this.$store.state[obj.setting.entity].editModel.id == null;
    }
    get gScrollbarClass() {
        if ((this as any).setting.scrollbarClass != null && (this as any).setting.scrollbarClass != undefined)
            return (this as any).setting.scrollbarClass;
        return ((this as any).setting.fullscreen ? 'drawer-fullscreen--scroll' : 'drawer-form--scroll');
    }
    get gTransition() {
        return ((this as any).setting.fullscreen ? 'dialog-bottom-transition' : 'dialog-transition');
    }
    get loading() {
        if ((this as any).setting.entity != undefined)
            return this.$store.state[(this as any).setting.entity][(this as any).setting.loadingProp];
        else
            return false;
    }
    get saveAction() {
        let obj = (this as any);
        return obj.setting.entity + '/' + (obj.setting.action != undefined ? obj.setting.action : (obj.model.id != null ? 'update' : 'create'));
    }
    get gTitle() {
        let obj = (this as any);
        let title = '';
        if (obj.setting.titleFull != undefined && obj.setting.titleFull != '')
            title = obj.setting.titleFull;
        else if (obj.setting.entity != undefined)
            title = (this.$store.state[obj.setting.entity].editModel.id != null ? this.L('Editar') : this.L('Agregar')) + ' ' + (obj.setting.title != '' ? obj.setting.title : this.L(obj.setting.entity));
        else 
            title = (obj.setting.title != '' ? obj.setting.title : this.L(obj.setting.entity));
        return title;
    }

    @Emit('on-init-form')
    onInit() {
        
    }

    @Emit('on-ok')
    async save() {
        let value = ((this as any).setting.showDialog ? this.value : true);
        if (!(this as any).setting.disabledSave &&
            !(this as any).setting.customSave && value &&
            this.$refs.entityForm != undefined && (this.$refs.entityForm as any).validate()) {
            let model = Util.prepEntity(this, this.items, (this as any).model);
            let newModel = await this.$store.dispatch({
                type: this.saveAction,
                data: model
            });
            if ((this as any).setting.resetForm) (this.$refs.entityForm as any).reset();

            if ((this as any).setting.closeOnSave) {
                Util.abp.notify.success(this, 'Guardado Correctamente');
                this.$emit('input', false);
                this.$emit('save-success', newModel);
            }

            return newModel;
        } else
            return false;
    }

    @Emit('on-print')
    async print() {
        //let obj = (this as any);
        //if (this.$store.state[obj.setting.entity].editModel.id != null) {
        //    await this.$store.dispatch({
        //        type: obj.setting.entity + '/print',
        //        id: this.$store.state[obj.setting.entity].editModel.id
        //    });
        //}
    }

    @Emit('on-cancel')
    cancel() {
        let obj = (this as any);
        if (obj.setting.showForm && obj.setting.cancelReset) {
            if (this.$refs.entityForm != undefined)
                (this.$refs.entityForm as any).reset();
            this.$store.commit((this as any).setting.entity + '/new');
        }
        if (obj.setting.closeOnCancel)
            this.$emit('input', false);
    } 

    @Emit('on-clean')
    clean() {
        if (this.$refs.entityForm != undefined)
            (this.$refs.entityForm as any).reset();
    }

    beforeRouteLeave(to, from, next) {
        // If the form is not dirty or the user confirmed they want to lose unsaved changes,
        // continue to next view
        if (!this.value || this.confirmLeave()) {
            next()
        } else {
            // Cancel navigation
            next(false)
        }
    }

    onBeforeUnload(e) {
        if (this.value && !this.confirmLeave()) {
            // Cancel the event
            e.preventDefault()
            // Chrome requires returnValue to be set
            e.returnValue = ''
        }
    }

    confirmLeave() {
        return window.confirm('Do you really want to leave? you have unsaved changes!')
    }

    created() {
        if (AppConst.setting.confirmLeave)
            window.addEventListener('beforeunload', this.onBeforeUnload)
    }

    beforeDestroy() {
        if (AppConst.setting.confirmLeave)
            window.removeEventListener('beforeunload', this.onBeforeUnload)
    }
}
