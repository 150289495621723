import Entity from '../entity'
import ApplicationProposal from './applicationproposal';
import ApplicationGood from './applicationgood';
import ApplicationDocument from './applicationdocument';

export default class Application extends Entity<number>{
    code: number;
    vertical_id: number;
    customer_id: number;
    user_id: number;
    user_risk_id: number;
    representative_id: number;
    guarantee_id: number;
    currency_id: number;
    state_id: number;
    evaluator_id: number;
    customer_type_id: number; //FINANCIERA
    annual_sale_id: number; //FINANCIERA
    segment_id: number; //FINANCIERA
    total_income: number; //FINANCIERA
    sub_total: number; //FINANCIERA
    total: number; 
    autonomy: string; //FINANCIERA
    special_perm: boolean; //FINANCIERA
    specification: string; //FINANCIERA
    warranty: string;
    description: string;
    notes: string;
    assets: number;
    exchange_rate: number;
    issue_date: string;
    observed_date: string;
    valid: boolean;
    observed: boolean;
    finished: boolean;
    request_behavior: boolean;
    attached_behavior: boolean;
    statement_aggreed: boolean;
    request_review: boolean;
    reviewed: boolean;
    approved: number;
    rejected: number;
    user_votes: Array<any>;
    votes: Array<any>;
    autonomy_process: string;
    emsa_company: string;
    update_notes: string;

    proposals: Array<ApplicationProposal>;
    goods: Array<ApplicationGood>;
    documents: Array<ApplicationDocument>;
    currency_sign: string;
    customer_name: string;
    representative_name: string;
    guarantee_name: string;
    existing: boolean;
}


