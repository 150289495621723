import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import ApplicationDocument from '../../entities/financial/applicationdocument'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Application from '@/store/entities/financial/application';

interface ApplicationDocumentState extends ListState<ApplicationDocument>{
    editModel:ApplicationDocument;    
    application: number;
    itemLoading: boolean;
}
class ApplicationDocumentModule extends ListModule<ApplicationDocumentState,any,ApplicationDocument>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<ApplicationDocument>(),
        loading: false,
        itemLoading: false,
        editModel: new ApplicationDocument(),
        application: 0,
        path: 'application-documents'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async upload(context: ActionContext<ApplicationDocumentState, any>, payload: any) {
            context.state.itemLoading = true;
            //payload.data.application_id = context.state.application;
            let response = await Ajax.post('/api/application-documents-upload', payload.data).catch(e => {
                context.state.itemLoading = false;
                return Promise.reject(e);
            });
            context.state.itemLoading = false;
            context.state.list = response.data.result;
        },
        async validate(context: ActionContext<ApplicationDocumentState, any>, payload: any) {
            context.state.itemLoading = true;
            //payload.data.application_id = context.state.application;
            let response = await Ajax.post('/api/application-documents-validate', payload.data).catch(e => {
                context.state.itemLoading = false;
                return Promise.reject(e);
            });
            context.state.itemLoading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        init(state: ApplicationDocumentState, model: Application) {
            state.editModel = Object.assign({});
            state.application = 0;
            if (model != undefined) {
                state.application = model.id;
            }
        },
        new(state: ApplicationDocumentState, model: ApplicationDocument) {
            state.editModel = Object.assign({});
            state.editModel.filename = null;
            state.editModel.fileData = null;
        },
    });
}
const applicationdocumentModule=new ApplicationDocumentModule();
export default applicationdocumentModule;