import Entity from '../entity'

export default class Notification extends Entity<number>{
    notifiable_id: number;
    type: number;
    title: string;
    description: string;
    icon: string;
    readed: boolean;
    created_at: string;
    data: string;
}


