import Entity from '../entity'

export default class Alert extends Entity<number>{
    frecuency_id: number;
    send_type_id: number;
    name: string;
    subject: string;
    content: string;
    active: boolean;
}


