













































import { Component, Vue,Inject, Prop,Watch } from 'vue-property-decorator';
import AbpBase from '../../lib/abpbase'
import ModalView from '@/components/helper/modal-view.vue';
import VWidget from '@/components/VWidget.vue';

@Component({
    components: { ModalView, VWidget }

})
export default class ShortKeys extends AbpBase {
    name: string = 'shortKeys';
    showKeys: boolean = false;
    modalSetting: any = {
        title: 'Atajos del Teclado',
        width: "800px",
        icon: "keyboard",
        showForm: false,
        persistent: false,
        showFootActions: false
    };

    get keys_list() {
        let keyG = [
            { help_key: 'ALT', key: 'H', text: 'Abrir Ayuda' },
            { help_key: 'ALT', key: 'F', text: 'Pantalla Completa' },
            { help_key: 'ALT', key: 'M', text: 'Contraer / Expandir Menu' },
        ];
        let keyL = [
            { help_key: 'ALT', key: 'R', text: 'Actualizar Lista' },
            { help_key: 'ALT', key: 'N', text: 'Nuevo Registro' },
            { help_key: 'ALT', key: 'A', text: 'Agregar Registro' },
            { help_key: 'ALT', key: 'E', text: 'Exportar a Excel' }
        ];
        let keyM = [
            { key: 'ESC', text: 'Cerrar Ventana' },
            { help_key: 'ALT', key: 'C', text: 'Cancelar Ventana' },
            { help_key: 'ALT', key: 'G', text: 'Guardar Registro' },
            { help_key: 'ALT', key: 'P', text: 'Imprimir Documento' }
        ];

        return [
            { title: 'General', icon: 'settings', keys: keyG },
            { title: 'Listas', icon:'list', keys: keyL },
            { title: 'Ventanas', icon: 'desktop_windows', keys: keyM }
        ]
    }

    show() {
        this.showKeys = true;
    }
    mounted () {
    
    }
}
