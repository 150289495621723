import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Analyst from '../../entities/catalogs/analyst'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface AnalystState extends ListState<Analyst>{
    editModel:Analyst;
    
}
class AnalystModule extends ListModule<AnalystState,any,Analyst>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Analyst>(),
        loading:false,
        editModel: new Analyst(),
        activeList: new Array<Analyst>(),
        path: 'analysts'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions        
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: AnalystState, model: Analyst) {
            state.editModel = model;
        },
    });
}
const analystModule=new AnalystModule();
export default analystModule;
