import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Concept from '../../entities/financial/concept'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface ConceptState extends ListState<Concept>{
    editModel:Concept;
    
}
class ConceptModule extends ListModule<ConceptState,any,Concept>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Concept>(),
        loading:false,
        editModel: new Concept(),
        activeList: new Array<Concept>(),
        path: 'concepts'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const conceptModule=new ConceptModule();
export default conceptModule;
