declare global {
    interface RouterMeta {
        title: string;
    }
    interface Router { 
        path: string;
        name: string;
        icon?: string;
        permission?: string;
        meta?: RouterMeta;
        component: any; 
        children?: Array<Router>;
    } 
    interface System {
        import(request: string): Promise<any>
    }
    var System: System
}
import login from '../views/login.vue'
import home from '../views/home/home.vue'
import main from '../views/main.vue'
import Ajax from '../lib/ajax';

export const locking = { 
    path: '/locking',
    name: 'locking',
    component: () => import('../components/lockscreen/components/locking-page.vue')
};
export const loginRouter: Router = {
    path: '/',
    name: 'login',
    meta: {
        title: 'LogIn'
    },
    component: () => import('../views/login.vue')
};
export const otherRouters: Router = {
    path: '/main',
    name: 'main',
    permission: '', 
    meta: { title: '' },
    component: main,
    children: [  
        { path: 'home', meta: { title: 'Dashboard' }, name: 'home', component: () => import('../views/home/home.vue') },
        { path: '/admin/users/profile', meta: { title: 'Perfil de usuario' }, name: 'profile', component: () => import("../views/admin/user/profile.vue") },
        { path: '/security', meta: { title: 'Seguridad' }, name: 'security', component: () => import("../views/admin/security/security.vue") },
        { path: '/preferences', meta: { title: 'Preferencias' }, name: 'preferences', component: () => import("../views/preferences/configuration/configuration.vue") },
        //{ path: '/reports', meta: { title: 'Reportes' }, name: 'reports', component: () => import("../views/reports/reports.vue") },
        //{ path: '/reports/sales-sellers', meta: { title: 'Ventas por Vendedor' }, name: 'rsalessellers', component: () => import("../views/reports/sales/sales-sellers.vue") },
    ]
}
export const appRouters: Array<Router> = [];

export const dbRouters = {
    user: () => import("../views/admin/user/user.vue"),
    role: () => import("../views/admin/role/role.vue"),
    tab: () => import("../views/admin/tab/tab.vue"),
    company: () => import("../views/admin/company/company.vue"),

    analyst: () => import("../views/catalogs/analyst/analyst.vue"),
    customer: () => import("../views/catalogs/customer/customer.vue"),
    alert: () => import("../views/catalogs/alert/alert.vue"),

    application: () => import("../views/financial/application/application.vue"),
    financialstatement: () => import("../views/financial/financialstatement/financialstatement.vue"),
    riskreport: () => import("../views/financial/riskreport/form.vue"),
    //document: () => import("../views/extranet/document/document.vue"),
     
        
    //generaltype: () => import("../views/preferences/generaltype/generaltype.vue"),
    //preferences: () => import("../views/preferences/configuration/configuration.vue"),
} 

export const routers = [
    loginRouter,
    locking,
    ...appRouters,
    otherRouters
];
