import Entity from '../entity'

export default class ApplicationComment extends Entity<number>{
    application_id: number;
    user_id: number;
    comment: string;
    filename: string;
    fileData: string;
    filetype: string;
}


