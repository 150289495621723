import Entity from '../entity'

export default class ApplicationProposal extends Entity<number>{
    application_id: number;
    operation_id: number;
    currency_id: number;
    model: string;
    vehicle_type: string;
    quantity: number;
    total_amount: number;
    months: number;
    day_fee: number;
    month_fee: number;
    price: number;
    equipment: number;
    total: number;
}


